.alum-header {
  padding: 24px;
}

.alum-header .alum-link {
  font-family: founder-grotesk;
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: right;
  text-decoration: underline;
}

.mb-alum-title {
  font-family: Aventa;
  font-size: 48px;
  font-weight: 400;
  line-height: 48px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--rainforest);

}

.alum-year-wrap:not(.show-all) .alum-list-wrapper {
  background-color: var(--lite-lemon);
}

.mb-alumini-year {
  font-family: Aventa;
  font-size: 72px;
  font-weight: 400;
  line-height: 72px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--rainforest);
}

.alum-year-wrapper{
  overflow: hidden;
}
