.wit-section-container {
  margin: auto;
  height: 100vh;
  overflow: hidden;
}
.about-nav{
  padding: 16px 54px;

}
.wit-about-div{
  position: relative;
}
.about-descr{
  justify-content: center;
  align-items: center;
  height: 100%;
}


.about-descr p {
  width: 75%;
  margin: auto;
  font-family: aventa;
  font-size: 72px;
  font-weight: 400;
  line-height: 79px;
  letter-spacing: 0em;
  text-align: center;
}
.about-intro{
  background: url("../../../../common/assets/img/about-bg.png");
  background-repeat: no-repeat;
  background-position: left;
  min-height: 100vh;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
}

@media screen and (max-width:1000px) {
  .about-descr p {
    width: 100%;
  }
}
@media screen and (max-width:767px) {
  .about-intro {
    background-image: url("../../../../common/assets/img/about-bg-sm.png");
    background-position: top;
    background-size: contain;
  }
  .about-descr p {
    font-size: 30px;
    line-height: 40px;
    width: 90%;
  }
}
