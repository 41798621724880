.wit-requirement-hero {
  font-family: founder-grotesk;
  max-width: 800px;
  width: 100%;
  margin: auto;
}

.wit-requirement-hero .wit-requirement-heading {
  font-weight: 700;
  font-size: 96px;
  line-height: 100%;
  color: var(--dark-blue);
}

.wit-requirement-hero .wit-small-text {
  font-weight: 400;
  font-size: 29px;
  margin-left: 20px;
  max-width: 400px;
  width: 100%;
}

.wit-requirements{
  align-items: flex-end;
}

.wit-req-card {
  max-width: 516px;
  min-width: 516px;
  width: 100%;
  padding: 20px 40px 0;
  height: fit-content;
  border: 2px solid var(--black);
  border-radius: 30px;
  position: relative;
}

.wit-sys-admin {
  background-color: var(--grey);
}

.wit-req-card-img {
  position: absolute;
  right: 0;
  top: -40px;
}
.wit-req-card-img img {
  width: 80%;
}

/* .wit-req-div .about-scroll-wrap .wit-req-card:nth-child(1) .wit-req-card-img,
.wit-req-div .about-scroll-wrap .wit-req-card:nth-child(2) .wit-req-card-img {
  top: -45px;
} */

.wit-req-card h1 {
  font-weight: 700;
  font-size: 38px;
  color: var(--dark-blue);
  line-height: 100%;
  font-family: aventa;
}

.wit-req-card ul {
  width: 96%;
}
.wit-req-card ul li{
  margin: 8px 0 16px;
  font-size: 15px;
  font-family: Inter;
}

.qa-card {
  background-color: var(--yellow-alt);
}

.fe-card {
  background-color: #CDF3FF;
  max-height: 700px;
}

.pm-card {
  background-color: #FEE2D1;
}

@media screen and (max-width:768px) {
  .wit-requirements{
    align-items: center;
  }
  .wit-requirement-hero {
    max-width: 600px;
  }
  .wit-req-card-img img{
    width: 50%;
  }
  .wit-req-card-img {
    right: -50px;
    top: -12px;
}
.wit-req-card h1{
  font-size: 34px;
}
.about-scroll-wrap .wit-req-card:nth-child(1) .wit-req-card-img{
  right: -30px;
}
.about-scroll-wrap .wit-req-card:nth-child(4) .wit-req-card-img{
  right: -90px;
}
.about-scroll-wrap .wit-req-card:nth-child(6) .wit-req-card-img{
  right: -20px;
}
}
@media screen and (max-width:328px) {
  .wit-req-card{
    padding: 20px 10px;
  }
}