.wit-modal-wrap {
  position: fixed;
  min-height: 100vh;
  background-color: rgba(0, 0, 0, 0.85);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 9999;
  justify-content: flex-end;
  height: 100%;
  display: none;
}

.wit-modal-wrap.show-modal {
  display: flex;
}

.wit-apply-modal {
  font-family: founder-grotesk;
  max-width: 600px;
  width: 100%;
  overflow-x: hidden;
  background-color: var(--blue);
}

.wit-modal-header h2 {
  font-family: aventa;
  font-size: 44px;
  font-weight: 400;
  line-height: 64px;
  letter-spacing: 0em;
  text-align: left;

  color: var(--dark-blue);
}

.wit-modal-header p {
  font-family: Inter;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
}

.wit-modal-content {
  background-color: var(--sky-blue);
  width: 100%;
  overflow: auto;
  height: 100%;
}

.wit-modal-body {
  display: flex;
  height: 100%;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.wit-available-careers {
  padding: 20px;
  width: 80%;
}

.wit-available-careers .accordion-item {
  border: 2px solid var(--black);
  box-shadow: 0px 15px 17px rgba(0, 41, 114, 0.15);
  border-radius: 16px;
}

.accordion-body {
  width: 95%;
}

.accordion-button:not(.collapsed) {
  color: var(--black);
}

.accordion-button:not(.collapsed) {
  background-color: unset;
  box-shadow: unset;
}

.wit-available-careers .accordion-flush .accordion-item:last-child {
  border-bottom: 2px solid var(--black);
}

.wit-available-careers .accordion-flush .accordion-item:first-child {
  border-top: 2px solid var(--black);
}

.accordion-flush .accordion-item .accordion-button {
  border-radius: 16px !important;
  cursor: pointer;
}

.wit-available-careers .accordion-button::after {
  border-radius: 29px;
  border: 1px solid var(--black);
  background-size: 80%;
  background-position: center;
  filter: grayscale(10);
}

.wit-accordion-header {
  display: flex;
  height: 70px;
}

.wit-accordion-header img,
.wit-accordion-img-span {
  width: 10%;
  margin-right: 10px;

}

.wit-accordion-img-span img {
  width: 60% !important;
  margin-left: auto;
}

.wit-apply-modal .accordion-flush .accordion-item .accordion-button,
.wit-apply-modal .accordion-flush .accordion-collapse {
  width: 300px;
  margin: auto;
}

@media screen  and (min-width: 500px) {
.wit-apply-modal .accordion-flush .accordion-item .accordion-button,
.wit-apply-modal .accordion-flush .accordion-collapse {
  width: 409px;
}

.wit-modal-header h2 {
  font-size: 64px;
}
}
@media screen  and (max-width: 300px) {
  .wit-apply-modal .accordion-flush .accordion-item .accordion-button,
  .wit-apply-modal .accordion-flush .accordion-collapse {
    width: 250px;
  }
  }

.wit-apply-modal ul li p {
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 10px;
  color: var(--dark-blue);
}

.role-title{
font-family: aventa;
font-size: 18px;
font-weight: 400;
line-height: 26px;
}