.wit-container {
  position: relative;
  max-width: 1400px;
  min-width: 250px;
  width: 100%;
  margin: auto;
  padding: 0.5rem 4rem 0;
}

.full-height {
  height: 100%;
}

.fixed-top {
  background-color: white;
}

h1{
  z-index: 1;
  position: relative;
}

.wit-nav {
  top: 0;
  padding-left: 4rem;
  padding-right: 4rem;
  height: 60px;
  width: 100vw;
}

.wit-nav .nav-link {
  text-transform: uppercase;
  color: var(--black) !important;
  text-align: center;
  line-height: 50%;
  margin: 0 10px;
  height: 30px;
  display: flex;
  align-items: center;
  /* padding-top: 14px; */
}

.wit-nav .active,
.nav-link:has(.active-scroll-spy),
.wit-home-nav span:hover {
  border: 1px solid var(--black);
  border-radius: 25px;
}

.profile-scroll-wrap {
  padding: 4rem 0 4rem 1rem;
}

/* faqs */
.faq-accordion-wrap {
  background-image: url("../common/assets/img/faqbg.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 75%;
}

.faq-container {
  max-width: 1080px;
  width: 100%;
}

.faq-accordion {
  border: 1px solid var(--black) !important;
}

.faq-accordion .accordion-button:not(.collapsed)::after {
  background-image: url("../common/assets/icons/minus.svg");
  transform: unset;
}

.faq-heading {
  color: var(--dark-blue);
  font-weight: 700;
  font-size: 96px;
  line-height: 100%;
}

.faq-accordion .accordion-button::after {
  background-image: url("../common/assets/icons/plus.svg");
  transform: unset;
}

sub {
  vertical-align: top;
  font-size: 0.6em;
  line-height: 28px;
  display: inline-block;
}

/*  footer */
.wit-footer {
  background-color: var(--yellow);
  margin: 0;
}

.wit-footer .wit-scroll {
  border-color: #0361F0;
}

.wit-footer .social-icons li {
  padding-right: 10px;
}

.wit-footer img {
  cursor: pointer;
}

.wit-order-2 {
  gap: 30px;
}

.wit-footer .policy {
  gap: 45px;
}

.wit-bounce {
  animation-name: w-bounce;
  animation-timing-function: linear;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  transform-origin: bottom;
}


.wit-bounce-up {
  animation-name: w-bounce-up;
  animation-timing-function: linear;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  transform-origin: bottom;
}

@keyframes w-bounce {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(10px);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes w-bounce-up {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-10px);
  }

  100% {
    transform: translateY(0);
  }
}

.delay-1 {
  animation-delay: .35s;
}

.delay-2 {
  animation-delay: 1.2s;
}

.delay-3 {
  animation-delay: 1.6s;
}

.secret-text-group {
  margin: auto;
  padding: 20px;

}

.secret-text-group .wit-small-text {
  max-width: 326px;
  vertical-align: top;
  font-weight: 400;
  font-size: 16.5px;
  margin-left: 10px;
}

.secret-text-group .big-text {
  font-family: founder-grotesk;
  font-style: normal;
  font-weight: 700;
  display: inline-block;
  font-size: 70px;
  max-width: 700px;
  width: 100%;
  line-height: 100%;
  color: var(--dark-blue);
}

.wit-secret {
  max-width: 940px;
  width: 100%;
  min-height: 300px;
  background: #CDDDFC;
  border: 1.5px solid var(--black);
  box-shadow: 0px 19.3729px 21.9559px rgba(0, 41, 114, 0.15);
  border-radius: 0px 64px;
}

.for-mobile {
  display: none;
}

.for-desktop {
  display: block;
}

.wit-scroller {
  padding: 50px;
  display: flex;
  overflow: hidden;
  position: relative;
  gap: 20px;
}

.wit-header-wrap {
  padding: 100px 0;
}

.home-nav-wrapper {
  padding: 16px 24px 0;
}

@media screen and (max-width:768px) {
  .wit-nav {
    padding: 10px;
  }

  .wit-scroller {
    padding: 24px;
  }

  .wit-requirement-hero .wit-requirement-heading {
    font-size: 60px !important;
  }

  .wit-requirement-hero .wit-small-text {
    max-width: 200px !important;
    font-size: 18px !important;
    margin-left: 10px !important;
  }

  .wit-header-wrap {
    padding: 100px 24px !important;
  }

  .wit-nav {
    padding: 24px !important;
  }

  .wit-scroller {
    flex-wrap: wrap;
    padding: 24px;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
}

@media screen and (max-width:500px) {
  .wit-nav {
    display: flex;
    flex-wrap: nowrap;
  }

  .wit-nav .text-end {
    font-size: 12px;

  }

  .wit-nav img {
    width: 80%;
  }
  .panel.card {
    min-width: 300px;
    max-width: 444px;
  }
  .about-scroll-wrap > div{
    max-width: 444px!important;
   }
}

@media screen and (max-width:768px) {
  .for-mobile {
    display: block;
  }

  .for-desktop {
    display: none;
  }
}

@media screen and (max-width:915px) {
  .wit-order-3 {
    order: 3;
    text-align: center;
  }
}

@media screen and (max-width:767px) {
  .home-nav-wrapper li {
    font-size: 13px;
  }

  .home-nav-wrapper img {
    width: 80%;
  }

  .wit-home-nav {
    display: none!important;
  }

  .panel.card.card h1, .wit-card-group .about-card h1 {
    font-size: 30px !important;
  }
}

@media screen and (max-width:328px) {
  .panel.card.card {
    min-width: 200px !important;
  }

  .wit-req-card h1 {
    font-size: 20px !important;
  }

  .wit-footer .policy {
    display: none !important;
  }
}

@media screen and (max-width:1000px) {
  .wit-container {
    padding: 0.8rem 1rem 0;
  }
}

@media screen and (min-width:768px) {
  .wit-nav {
    min-width: 250px;
  }
}